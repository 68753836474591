<template>
  <AuthLayout>
    <p class="text-center italic">Bezig met uitloggen.</p>
  </AuthLayout>
</template>

<script>
import { onMounted } from '@vue/composition-api';
import useAuth from '@/compositions/useAuth';
import AuthLayout from '@/modules/auth/views/layouts/auth';

export default {
  name: 'Logout',

  components: {
    AuthLayout,
  },

  setup(props, { root }) {
    const { logout } = useAuth();

    onMounted(() => {
      logout();

      setTimeout(() => {
        root.$router.replace({
          name: 'login',
        });
      }, 500);
    });
  },
};
</script>
